import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero } from '../components/common';
import { Container } from 'react-bootstrap';

const Bulkemail = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Bulk Email" innerpara="Email Marketing gives you the opportunity to connect one-on-one with your target audience and offers you the best ROI." />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Bulk Email" />
          <Paragraph para="Email Marketing gives you the opportunity to connect one-on-one with your target audience and offers you the best ROI. Engage your existing and potential customers with special offers, announcements and brand stories for continuously being on top of their mind. For developing a long-lasting relationship with your customers, email marketing is a cost-efficient and result-oriented digital media option. Our experts deploy the latest tools, conceptualise and implement a robust email marketing strategy, and track the results for optimizing the next round. We help brands deliver higher email open and click rates, reduced unsubscribe rates and minimum spam complaints. We can also deliver live content, brand videos and social feeds in the email for making it more engaging and interactive. From designing the mailer template, to writing crisp and persuasive content, to tracking and reporting – we take care of the entire email marketing campaign for helping you meet your business goals and impact your sales and profitability." />
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Bulkemail;
import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero } from '../components/common';
import { Container } from 'react-bootstrap';

const Socialmedia = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Social Media Marketing" innerpara="Our expertise lies in creating valuable digital customer journeys which culminate in meeting your business goals. We specialize in delivering personalised service to every client irrespective of scale or budget.." />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Social Media Marketing" />
          <Paragraph para="We are a full service digital marketing agency that seamlessly combines strategy and execution for engaging your potential customers across all digital platforms and helping you meet your business goals. From website design & development to creating and implementing a result-oriented dynamic content marketing strategy to measuring campaign performance – we possess industry-level skills for optimizing your digital marketing endeavour. We initialise unique methodologies to understand and define a roadmap for enhanced customer experience." />
          <Paragraph para="Our expertise lies in creating valuable digital customer journeys which culminate in meeting your business goals. We specialize in delivering personalised service to every client irrespective of scale or budget. By delivering 360° Digital Marketing services across the entire digital marketing value-chain, we help your business connect with customers. We understand user experience and interface design and deploy human centric innovation for creating customer experiences by mapping their journey across multiple digital touchpoints by engaging strategies and properties." />
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Socialmedia;
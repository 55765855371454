import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero } from '../components/common';
import { Container } from 'react-bootstrap';

const Webcreation = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Website Creation" innerpara="Do you wish to embrace a career which is in line with today’s digital demand? Then our Website Development Workshop is your perfect choice." />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Website Creation" />
          <Paragraph para="Do you wish to embrace a career which is in line with today’s digital demand? Then our Website Development Workshop is your perfect choice. A website is the first digital asset created by a business for optimizing its digital presence. Through a website, marketers can creatively showcase their products and services through a combination of attractive images and persuasive content. Good website developers who can think out of the box and create a winning website are in great demand by website development agencies and organizations. In this workshop, you will learn how to create websites using HTML, CSS, JavaScript and node.js. It does not matter is you have no prior knowledge of coding, you can still attend this workshop and learn the ropes of creating a website that helps a business meet its goals." />
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Webcreation;
import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero, Lbrake } from '../components/common';
import { Container } from 'react-bootstrap';

const Dmessage = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Director Message" innerpara="-" />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Director Message" />
          <Paragraph para="As the Director and Founder of RABS Net Solutions, it’s my pleasure to introduce to you our dynamic organization, – with the solutions we offer. I thank you for reviewing the website of RABS Net Solutions, a reputed provider of Security & ICT solutions in world. It is by virtue of our never-ending zeal to satisfy our Clients, we have a leadership position in this industry." />
          <Paragraph para="Our vision is to be the leader in ICT and security services. With that in mind, we always look forward to add real value to your business through our analytical and inventive ways with every single project. As an ICT & Security system provider, we try to adhere to several technology processes, but at the same time we keep constant vigilance to ensure that our Clients feel that we are also a people company. I believe in the strength of our services and solutions are in our technical and managerial staff. But apart from that, I also would like to believe that our strength and growth potential is in the trust placed upon us by our Clients. So at the onset of this new decade I bow to you and extend my eternal gratitude to your co-operation and trust, that has taken us to this position of leadership." />
          <Lbrake />
           
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Dmessage;
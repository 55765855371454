import React from 'react';

const Workshops = [
  {
    id: 1,
    image: 'imgs/workshop1.svg',
    head: "Digital Marketing Workshop",
    description:
      "With digital marketing growing at an explosive pace, your marketing and sales teams need to acquire the latest digital marketing knowledge for staying on top of the digital business management curve. We will conduct in-house digital marketing workshops at your site for empowering your team with updated theoretical and practical knowledge on how to leverage this powerful tool for helping your organization achieve its business goals. Our workshops are designed for marketing and sales professionals across different designation hierarchies.",
  },
  {
    id: 2,
    image: 'imgs/workshop2.svg',
    head: "Website Development Workshop",
    description:
      "Do you wish to embrace a career which is in line with today’s digital demand? Then our Website Development Workshop is your perfect choice. A website is the first digital asset created by a business for optimizing its digital presence. Through a website, marketers can creatively showcase their products and services through a combination of attractive images and persuasive content. Good website developers who can think out of the box and create a winning website are in great demand by website development agencies and organizations. In this workshop, you will learn how to create websites using HTML, CSS, JavaScript and node.js. It does not matter is you have no prior knowledge of coding, you can still attend this workshop and learn the ropes of creating a website that helps a business meet its goals.",
  },
];

export default Workshops;
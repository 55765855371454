import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero } from '../components/common';
import { Container } from 'react-bootstrap';

const Androidapp = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Android Application" innerpara="According to a Forrester research study, 63% of mobile apps are discovered through keyword searches on the app store." />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Android Application" />
          <Paragraph para="Brands create mobile applications for staying connected with their customers by sending regular push notifications. These mobile apps are uploaded on app stores like Google Play and iOS App Store. However, getting your mobile app discovered among the thousands of apps available in the online app stores is a big challenge. According to a Forrester research study, 63% of mobile apps are discovered through keyword searches on the app store. Like in the search engine, your customers also search for apps in the Appstore by typing the relevant keywords. If your mobile app does not show up in the initial results, you’ve lost your potential customers. We research and collate the keywords and key phrases your customers use to discover apps similar to yours. We offer result-oriented App Store Optimization services for optimizing your mobile app to rank higher in the app store’s search results. This increased visibility will lead to increased visitor traffic to your app page and increase your app downloads." />
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Androidapp;
import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero, Lbrake } from '../components/common';
import { Container } from 'react-bootstrap';

const About = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Who we are ?" innerpara="RABS Stands for RISE ABOVE BRILLANCY STANDARD." />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="About us" />
          <Paragraph para="RABS Net Solutions is a firm that provides complete business solutions. We are a marketing agency that typically focuses on results-based marketing in the digital world. Starting from the initial days of the company uphill the company has achieved its aim and leading towards growth. We have every branding service your company requires. We Build Brands, We Build YOU!" />
          <Paragraph para="The drive and dedication ensured us to deliver true value to businesses since 2013. Fast forward to 2021 & we’re still in love with delivering online solutions at the highest levels of creativity and execution. “RABS Net Solutions is a Google Official Badge Partner” We, The team of RABS Net Solutions are beyond Brilliancy and we are always committed to TAKING Your FORWARD." />
          <Lbrake />
          <Sectionhead title="Our Vision" />
          <Paragraph para="To be the pioneers of the information technology Industry." />
          <Lbrake />
          <Sectionhead title="Our Mission" />
          <Paragraph para="To Carter to each client as a fewer their parent with the same amount of love, care, and nurturing for their company and their business because when they grow, we grow with them." />
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default About;
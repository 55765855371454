import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import './common.css';

// body starts
export const Herotitle = (props) => {
    return (
        <h2 className='herotitle'>{props.htitle}</h2>
    )
};
export const Sectionhead = (props) => {
    return (
        <div className="sectionhead">
            <h2>{props.title}</h2>
        </div>
    )
};
export const Overviewpara = (props) => {
    return <p className='overviewpara'><b>{props.bold1}</b> {props.para} <br /><b>"{props.bold2}"</b></p>;
};
export const Innerhero = (props) => {
    return (
        <section id="innerhero" className='d-flex justify-cntent-center align-items-center'>
          <Container className="text-center">
            <Row className="justify-content-center">
              <Col sm={12} xl={9}>
                <Herotitle htitle={props.innertitle} /> 
                <Paragraph para={props.innerpara} />
              </Col>
            </Row>
          </Container>
        </section>
    );
}
// ends

// footer start
export const Heading = (props) => {
    return <h6 className='heading'>{props.head}<hr className='headhr' /></h6>;
};
export const Namenumber = (props) => {
    return <a className='namenumber' href={'tel:'+props.number}><i className={'csticon '+props.icon}></i>&nbsp;{props.name+' : '+props.number}<hr className='namenumhr' /></a>;
};
export const Emailid = (props) => {
    return <a className='emailid' href={'mailto:'+props.email}><i className={'csticon '+props.icon}></i>&nbsp;{props.email}<hr className='emailhr' /></a>;
};
export const Paragraph = (props) => {
    return <p className='paragraph'>{props.para}<b>{props.bolder}</b>{props.para2}<Link to='/' className='cstlink'>{props.link}</Link></p>;
};
export const Frame = (props) => {
    return <iframe width="100%" height="200" src={props.address} className="border-0" />;
};
export const Fbtmlinks = (props) => {
    return <Link to={'/'+props.pagename} className='fbtmlinks'>{props.link}</Link>;
};
export const divider = () => {
    return <span className='clrwhite'> | </span>;
};
// footer ends

export const Lbrake = () => {
    return <br />;
};
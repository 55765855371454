import React from 'react';

const Cards = [
  {
    id: 1,
    icon: "fab fa-android",
    head: "Android Application",
    description:
      "Brands create mobile applications for staying connected with their customers by sending regular push notifications...",
    color: "#3fcdc7",
    iconbgcolor: "#e6fdfc",
    pagename: 'androidapp',
  },
  {
    id: 2,
    icon: "fas fa-mail-bulk",
    head: "Bulk Email",
    description:
      "Email Marketing gives you the opportunity to connect one-on-one with your target audience and offers you the ...",
    color: "#2282ff",
    iconbgcolor: "#e1eeff",
    pagename: 'bulkemail',
  },
  {
    id: 3,
    icon: "far fa-comment-alt",
    head: "Bulk SMS",
    description:
      "Our state-of-the-art messaging system enables you to connect with your potential customers on their mobile phone...",
    color: "#41cf2e",
    iconbgcolor: "#eafde7",
    pagename: 'bulksms',
  },
  {
    id: 4,
    icon: "far fa-registered",
    head: "Domain Registration",
    description:
      "Building a website for showcasing your business to national and international customers, and the first step towards ...",
    color: "#3fcdc7",
    iconbgcolor: "#e6fdfc",
    pagename: 'domainreg',
  },
  {
    id: 5,
    icon: "fas fa-share-alt",
    head: "Social Media Marketing",
    description:
      "We are a full service digital marketing agency that seamlessly combines strategy and execution for engaging your...",
    color: "#ff689b",
    iconbgcolor: "#fceef3",
    pagename: 'socialmedia',
  },
  {
    id: 6,
    icon: "fas fa-desktop",
    head: "Website Creation",
    description:
      "The digital ecosystem has made it mandatory for a business to have a website. Not having a website ensures that you ...",
    color: "#ff689b",
    iconbgcolor: "#fceef3",
    pagename: 'webcreation',
  },
  {
    id: 7,
    icon: "fas fa-sliders-h",
    head: "Real Estate CRM",
    description:
      "Now supercharge your lead management and maintain client relationships with RABS Real Estate CRM Software. ...",
    color: "#41cf2e",
    iconbgcolor: "#eafde7",
    pagename: 'crm',
  },
  {
    id: 8,
    icon: "fas fa-file-upload",
    head: "Web Hosting",
    description:
      "Whether you’re a professional web developer or creating your business website, we specialise in providing the ...",
    color: "#2282ff",
    iconbgcolor: "#e1eeff",
    pagename: 'hosting',
  },
];

export default Cards;
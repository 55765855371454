import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero, Lbrake } from '../components/common';
import { Container } from 'react-bootstrap';

const Refundcancel = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Refund & Cancellation Policy" innerpara="-" />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Refund & Cancellation Policy" />
          <Paragraph para="RABS NET SOLUTIONS reserves the right to refuse/cancel any order. RABS NET SOLUTIONS at its sole discretion may cancel any order(s):" />
          <Paragraph para="We are professionals known for keeping things very straight. We promise only for what we can do. Our aim is to offer you the best service." />
          <Lbrake /> 
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Refundcancel;
import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero, Lbrake } from '../components/common';
import { Container } from 'react-bootstrap';

const Termsprivacy = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Privacy Policy | Terms & Conditions" innerpara="-" />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Privacy Policy | Terms & Conditions" />
          <Paragraph para="Your privacy is important to us. It is RABS Net Solutions Private Limited's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, , and other sites we own and operate." />
          <Paragraph para="This policy is effective as of 15 October 2021 and was last updated on 15 October 2021." />
          <Lbrake /> 
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Termsprivacy;
import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero, Lbrake } from '../components/common';
import { Container } from 'react-bootstrap';

const Contact = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Contact us" innerpara="-" />
      <section className='section inneroverview'>
        <Container>
          
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Contact;
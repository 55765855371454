import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Herotitle, Sectionhead, Overviewpara, Paragraph } from '../common';
import Cards from './contents/cardscontent';
import { ServicesCard } from './components';
import Workshops from './contents/workshopcontents';
import { Workshop } from './components';
import './body.css';

const Body = () => {
  return (
    <>
      {/* Slide */}
        <section id="hero-no-slider" className='d-flex align-items-center '>
          <Container className="position-relative">
            <Row className="justify-content-center">
              <Col sm={12} xl={9}>
                <Herotitle htitle="Welcome to RABS Net Solutions Pvt. Ltd." />
                <Paragraph para="We create impactful digital customer experiences" />
                <Paragraph para="A digital-first integrated business promotion company" />
                <Paragraph para="Reach, Engage, Acquire & Convert customers through digital
                  conversations" />
              </Col>
            </Row>
          </Container>
        </section>
      {/* ends */}
      
      {/* Overview */}
        <section className='section'>
          <Container>
            <Sectionhead title="Complete Solutions at One Stop" />
            <Overviewpara bold1="RABS Net Solutions Pvt. Ltd." para="is a full services digital agency based in Mumbai, India. An agency with a strong growth trajectory and mindset, We are a team of passionate strategists, marketers, communicators and technologists. We collaborate with our clients in India and abroad on strategies and tactics to achieve measurable business results." bold2="Lets make digital magic together."/>
          </Container>
        </section>
      {/* ends */}

      {/* Services */}
        <section className='section bg-light'>
          <Container>
            <Sectionhead title="Services We Offer" />
            <Row>
              {Cards.map((card) => (
                <ServicesCard
                  key={card.id}
                  iconbgcolor={card.iconbgcolor}
                  color={card.color}
                  icon={card.icon}
                  head={card.head}
                  description={card.description}
                  pagename={card.pagename}
                />
              ))}
            </Row>
          </Container>
        </section>
      {/* ends */}

      {/* Workshops */}
        <section className='section workshop'>
          <Container>
            <Sectionhead title="Training Workshops" />
            {Workshops.map((workshop) => (
              <Workshop
                key={workshop.id}
                image={workshop.image}
                head={workshop.head}
                description={workshop.description}
              />
            ))}
          </Container>
        </section>
      {/* ends */}
    </>
  )
}

export default Body;
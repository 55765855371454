import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
// import logo from '../../../public/imgs/logo.png';
import '../header/header.css';
import { Navlink, Navdropdownitem } from '../body/components';

const Header = () => {
  // toogle menu on hover
  const [show, setShow] = useState(false);
  const showDropdown = (e)=> {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }
  // ends

  return (
    <Navbar expand="lg" variant="light" fixed="top" bg="light" className='p-0 bgcolr'>
      <Container>
        <Link to='/'>
          <img src="../../../imgs/logo.png" width="auto" height="auto" alt="logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto justify-content-end w-100">
            <Navlink pagename="/" name="Home" />
            <Navlink pagename="/about" name="About us" />
            <Navlink pagename="/dmessage" name="Director Message" />
            <NavDropdown title="Services" id="basic-nav-dropdown" show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
              <Navdropdownitem pagename="/androidapp" name="Android Application" />
              <Navdropdownitem pagename="/bulkemail" name="Bulk Email" />
              <Navdropdownitem pagename="/bulksms" name="Bulk SMS" />
              <Navdropdownitem pagename="/domainreg" name="Domain Registration" />
              <Navdropdownitem pagename="/socialmedia" name="Social Media Marketing" />
              <Navdropdownitem pagename="/webcreation" name="Website Creation" />
              <Navdropdownitem pagename="/crm" name="Real Estate CRM" />
              <Navdropdownitem pagename="/hosting" name="Web Hosting" />
              {/* <NavDropdown.Divider /> */}
            </NavDropdown>
            <Navlink pagename="/contact" name="Contact us" />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Innerhero } from '../components/common';

const Error = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="404 Page Not Found" innerpara="We couldn't find what you were looking for." />
      <Footer />
    </>
  )
};

export default Error;
import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero } from '../components/common';
import { Container } from 'react-bootstrap';

const Hosting = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Web Hosting" innerpara="Our plans are scalable in nature, so you need not spend a big amount in the beginning." />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Web Hosting" />
          <Paragraph para="Whether you’re a professional web developer or creating your business website, we specialise in providing the best hosting plans at affordable prices. Our plans are scalable in nature, so you need not spend a big amount in the beginning. Our website hosting packages are specially designed to service all levels of requirements. Depending on the hosting platforms, we provide hosting on both Windows and Linux servers for static as well as dynamic websites. Our easy to use dashboard and maximum level security and backup features makes us the preferred web hosting partner of small, medium and large scale businesses. We provide web hosting services across WordPress, Cloud, VPS Hosting, and Dedicated Server." />
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Hosting;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../footer/footer.css';
import { Heading, Namenumber, Emailid, Paragraph, Frame, Fbtmlinks, divider, Lbrake } from '../common';

const Footer = () => {
  const cols = [3, 4, 1, 4]; // grid
  const felements = cols.map((k, cols) =>
    <Col className={'col-12 col-md-'+k} key={cols.toString()}>
      {checkcols(cols)}
    </Col>
  );
  
  function checkcols(key) {
    switch(key) {
      case 0:
      return (
        <>
          <Heading head="Call Us" />
          <Namenumber icon="fas fa-mobile-alt" name="Bilal Halai" number="+919768344835" />
          <Namenumber icon="fas fa-mobile-alt" name="Rehan Halai" number="+919821112012" />
          <Emailid icon="far fa-envelope" email="info@rabsnetsolutions.com" />
          <Emailid icon="far fa-envelope" email="bilal@rabsnetsolutions.com" />
          <Emailid icon="far fa-envelope" email="rehan@rabsnetsolutions.com" />
        </>
      )
      case 1:
      return (
        <>
          <Heading head="Registered Address" />
          <Paragraph para="B-1908, Pearl Palace, Chandiwala Complex, Aqsa Masjid Road, Jogeshwari (West), Mumbai - 400 102, Maharashtra, India" />
          <Frame address="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30153.942827565574!2d72.84089!3d19.140844!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8cd367c2162ffb85!2sPearl%20Palace!5e0!3m2!1sen!2sin!4v1634287098102!5m2!1sen!2sin" />
        </>
      )
      case 3:
      return (
        <>
          <Heading head="Office Address" />
          <Paragraph para="RABS Business Centre, Ground Floor, Saraswati Building, Pushtikar Society, Opp. Iris Park, Patel Estate Road, Jogeshwari (West), Mumbai - 400102" />
          <Frame address="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30153.896978867444!2d72.848432!3d19.141095!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b632608f5fab%3A0x9be882d6de60d82!2sPushtikar%20Society%2C%20B%20R%20Nagar%2C%20Jogeshwari%20West%2C%20Mumbai%2C%20Maharashtra%20400102!5e0!3m2!1sen!2sin!4v1634287199589!5m2!1sen!2sin" />
        </>
      )
      // default:
      // return;
    }
  }
  
  return (
    <footer className='pt-5 pb-5 fbg'>
      <Container>
        <Row>{felements}</Row>
        <Row>
          <Col className='col-12 pt-5 text-center'>
            <Paragraph para="© 2021 " bolder="RABS Net Solutions Private Limited." para2=" | All Rights Reserved." />
            <Fbtmlinks pagename="termsprivacy" link="Terms & Conditions" />
            {divider()}
            <Fbtmlinks pagename="termsprivacy" link="Privacy Policy" />
            {Lbrake()}
            <Fbtmlinks pagename="refundcancel" link="Refund & Cancellation Policy" />
            {divider()}
            <Fbtmlinks pagename="faq" link="FAQ" /> 
            {Lbrake()}{Lbrake()}
            <Paragraph para="Designed by " link="RABS Net Solutions Pvt. Ltd." />
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;
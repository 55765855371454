import React, { Suspense } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import logo from './logo.svg'; // old
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './routes/home';
import About from './routes/about';
import Dmessage from '../src/routes/dmessage';
import Androidapp from '../src/routes/androidapp';
import Bulkemail from '../src/routes/bulkemail';
import Bulksms from '../src/routes/bulksms';
import Domainreg from '../src/routes/domainreg';
import Socialmedia from '../src/routes/socialmedia';
import Webcreation from '../src/routes/webcreation';
import Crm from '../src/routes/crm';
import Hosting from '../src/routes/hosting';
import Contact from '../src/routes/contact';
import Termsprivacy from '../src/routes/termsprivacy';
import Refundcancel from '../src/routes/refundcancel';
import Faq from '../src/routes/faq';
import Error from '../src/routes/error';

// const Home = lazy(() => import('./routes/home'));
// const About = lazy(() => import('./routes/about'));
// const Dmessage = lazy(() => import('./routes/dmessage'));
// const Androidapp = lazy(() => import('./routes/androidapp'));
// const Bulkemail = lazy(() => import('./routes/bulkemail'));
// const Bulksms = lazy(() => import('./routes/bulksms'));
// const Domainreg = lazy(() => import('./routes/domainreg'));
// const Socialmedia = lazy(() => import('./routes/socialmedia'));
// const Webcreation = lazy(() => import('./routes/webcreation'));
// const Crm = lazy(() => import('./routes/crm'));
// const Hosting = lazy(() => import('./routes/hosting'));
// const Contact = lazy(() => import('./routes/contact'));
// const Termsprivacy = lazy(() => import('./routes/termsprivacy'));
// const Refundcancel = lazy(() => import('./routes/refundcancel'));
// const Faq = lazy(() => import('./routes/faq'));
// const Error = lazy(() => import('./routes/error'));

const App = () => {
  return (
    <>
      <Router>
        <Suspense fallback={null} maxDuration={0}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/dmessage" element={<Dmessage />} />
            <Route path="/androidapp" element={<Androidapp />} />
            <Route path="/bulkemail" element={<Bulkemail />} />
            <Route path="/bulksms" element={<Bulksms />} />
            <Route path="/domainreg" element={<Domainreg />} />
            <Route path="/socialmedia" element={<Socialmedia />} />
            <Route path="/webcreation" element={<Webcreation />} />
            <Route path="/crm" element={<Crm />} />
            <Route path="/hosting" element={<Hosting />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/termsprivacy" element={<Termsprivacy />} />
            <Route path="/refundcancel" element={<Refundcancel />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="*" element={<Error to="/" />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;

// old bkp
// <div className="App">
//   <header className="App-header">
//     <img src={logo} className="App-logo" alt="logo" />
//     <p>
//       Edit <code>src/App.js</code> and save to reload.
//     </p>
//     <a
//       className="App-link"
//       href="https://reactjs.org"
//       target="_blank"
//       rel="noopener noreferrer"
//     >
//       Learn React
//     </a>
//   </header>
// </div>
import React from 'react';
import Header from '../components/header/header';
import Body from '../components/body/body';
import Footer from '../components/footer/footer';

const Home = () => {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
}

export default Home;
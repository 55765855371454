import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero, Lbrake } from '../components/common';
import { Container } from 'react-bootstrap';


const Faq = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Faq" innerpara="-" />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Faq" />
          <Paragraph para="For any questions or concerns regarding your privacy, you may contact us using the following details:" />
          <Lbrake /> 
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Faq;
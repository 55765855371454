import React from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Card} from 'react-bootstrap';
import './components.css';

export const Navlink = (props) => {
    return <Link to={props.pagename} className="navlink">{props.name}</Link>;
}
export const Navdropdownitem = (props) => {
    return <Link to={props.pagename} className="navlink dropdown-item">{props.name}</Link>;
}


export const ServicesCard = (props) => {
    const { icon, head, description, color, iconbgcolor, pagename } = props;
    return (
        <Col sm={12} md={3}>
            <Card className="service-box service-box-pink" style={{ ["--colorMain"]: color }}>
                <Card.Body>
                    <div className="service-icon" style={{ ["--iconbgcolor"]: iconbgcolor }}>
                        <i className={icon}></i>
                    </div>
                    <Card.Title className="service-title">{head}</Card.Title>
                    <Card.Text className="service-desc">{description}</Card.Text>
                    <Link to={'/'+pagename} className="service-btn">Read More</Link>
                </Card.Body>
            </Card>
        </Col>
    )
};

export const Workshop = (props) => {
    const { image, head, description } = props;
    return (
        <Row className='pt-5'>
            <Col sm={12} md={5}>
                <img src={image} width="100%" />
            </Col>
            <Col md={1} className="m-0 p-0"></Col>
            <Col sm={12} md={6} className="pt-2">
                <h3 className="wrokshop-title">{head}</h3>
                <hr style={{opacity:'0.2'}} />
                <p className="wrokshop-desc">{description}</p>
            </Col>
        </Row>
    )
};

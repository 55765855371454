import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero } from '../components/common';
import { Container } from 'react-bootstrap';

const Domainreg = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Domain Registration" innerpara="We provide seamless domain registration services across a wide range of domain extensions like .com, .in, .biz and many more." />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Domain Registration" />
          <Paragraph para="Building a website for showcasing your business to national and international customers, and the first step towards your digital presence in booking a domain name that appropriately reflects your company name and business. We provide seamless domain registration services across a wide range of domain extensions like .com, .in, .biz and many more at prices that are the lowest in the market. We offer country specific domains if you wish to give an international appeal to your digital identity. We can also assist you in acquiring an expired domain that best suits your business, at an affordable price." />
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Domainreg;
import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero } from '../components/common';
import { Container } from 'react-bootstrap';

const Crm = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Real Estate CRM" innerpara="Now supercharge your lead management and maintain client relationships with RABS Real Estate CRM Software like never before." />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Real Estate CRM" />
          <Paragraph para="Customer Relationship Management is one of the main pillars of Real Estate Sales and Property Management. They help in assisting users in maintaining accurate business data and provide them with a competitive edge to thrive and sustain in the aggressively competitive real estate industry. We at RABS Net Solutions Private Limited, provide you a comprehensive Real Estate CRM Software that connects every customer's data in one place to help you manage and sell your properties quicker." />
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Crm;
import React from 'react';
import '../components/body/body.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { Sectionhead, Paragraph, Innerhero } from '../components/common';
import { Container } from 'react-bootstrap';

const Bulksms = () => {
  return (
    <>
      <Header />
      <Innerhero innertitle="Bulk SMS" innerpara="We provide you with best-in-class real-time delivery reports and robust SMS gateway infrastructure." />
      <section className='section inneroverview'>
        <Container>
          <Sectionhead title="Bulk SMS" />
          <Paragraph para="Our state-of-the-art messaging system enables you to connect with your potential customers on their mobile phone with personalized Bulk SMS offers and updates. We provide you with best-in-class real-time delivery reports and robust SMS gateway infrastructure. Through our Bulk SMS services, you can communicate easily and swiftly with your customers, offer instant discount schemes and run promotional offers You can insert links to your website or product landing page for optimizing customer response." />
          <Paragraph para="Our special rates and exciting messaging offers will convince you to consider us as your mobile marketing partner. We assure you of quality and prompt service and dedicated customer care support. We offer all kinds of messaging services to meet the dynamic requirements of businesses across all size." />
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Bulksms;